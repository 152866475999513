<template>

  <DefaultCrudView
    @add-master-user="addUserMasterModal"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerMedium
  >
    <template slot="content">
    
      <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.users_id != loggedUserId"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="removeMasterModal(item)"
              >
                mdi-account-remove
              </v-icon>
            </template>
            <span>{{ $t('remove_privilege') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

    <DefaultModal :title="$t('Remove master privilege')" ref="removeMasterModal" @submit="removeMaster" deleteModal>
      <template v-slot:content>
        {{ $t('Are you sure you want to remove user ') }} <b>{{ selectedUser ? selectedUser.name : '' }}</b> {{ $t(' master privilege?') }}
      </template>
    </DefaultModal>

    <DefaultModal :title="$t('add_master_user')" ref="addUserMasterModal" @submit="saveUsersToBeMaster">
      <template v-slot:content>
        <UserEmailTextarea v-model="usersToBeMaster" />
      </template>
    </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import UserEmailTextarea from '@/components/core/UserEmailTextarea.vue'

import UserActions from '@/store/core/user/actions-types'
import UserMutations from '@/store/core/user/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "MasterUser",

  components: {
    DefaultCrudView,
    DefaultModal,
    UserEmailTextarea,
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapState({
      loggedUserId: state => state.core.auth.userData.users_id,
      selectedUser: state => state.core.user.selectedUser,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('master_users'),
          prependIcon: this.getIconForCoreSettingsItem('master_users'),
        },
      ]
    },
  },

  data() {
    return {
      loading: false,
      users: [],
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },
      itemsLength: 0,
      usersToBeMaster: [],
      actions: [
        {
          text: "add_master_user",
          eventToEmit: "add-master-user"
        },
      ],
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
    }
  },

  mounted() {
    this.loading = true

    this.reloadData()
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('core/user', [
      UserActions.REMOVE_MASTER_PRIVILEGE,
      UserActions.GET_ALL_USERS,
      UserActions.CREATE_USER_BATCH,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapMutations('core/user', [
      UserMutations.SET_SELECTED_USER,
      UserMutations.SET_INACTIVE_FILTER_VAL,
    ]),

    reloadData() {
      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      options = {
        ...options,
        'filter[only_master]' : "true"
      }

      this.loading = true
      this.[UserActions.GET_ALL_USERS](options)
        .then((result) => {
          this.users = [...result.items]

          this.itemsLength = result.total

          this.loading = false
        })
    },

    addUserMasterModal() {
      this.$refs.addUserMasterModal.openModal()
    },

    saveUsersToBeMaster() {
      let params = {
        emails: this.usersToBeMaster,
        type: 'master',
        link: window.location.origin
      }

      this.[UserActions.CREATE_USER_BATCH](params)
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('User(s) created'))

          this.$refs.addUserMasterModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    removeMasterModal(user) {
      this.[UserMutations.SET_SELECTED_USER](user)
      this.$refs.removeMasterModal.openModal()
    },

    removeMaster() {
      this.[UserActions.REMOVE_MASTER_PRIVILEGE]()
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Master privilege removed'))

          this.$refs.removeMasterModal.closeModal()

          this.reloadData()
        })
    },
  }
}

</script>
<template>
  <v-textarea
    v-model="contentLocal"
    :hint="$t('enter_emails_separated_commas')"
    :rules="rules"
    label="Email"
    autocomplete="email"
    rows="3"
    outlined
    autofocus
  ></v-textarea>
</template>

<script>

export default {
  name: 'UserEmailTextarea',

  props: {
    content: {
      type: Array
    },

    rules: {
      type: Array
    }
  },

  model: {
    prop: 'content',
    event: 'content-change'
  },

  data() {
    return {
      textVal: "",
    }
  },

  computed: {
    
    contentLocal: {
      get() {
        return this.textVal;
      },

      set(value) {
        if (!value) {
          value = ""
        }
        
        const arrayVal = value.split(/[\s,]+/)

        this.textVal = value

        this.$emit('content-change', arrayVal)
      }
    }
  },
}
</script>